import React from "react"
import ArchiveLayout from "../components/archivelayout"
import SEO from "../components/seo"
import {Container, Row, Col, Badge, ListGroup, ListGroupItem} from "reactstrap"
import {slugify} from "../util/utilityFunctions"
import Sidebar from "../components/sidebar"
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"

const tagsPage = ({pageContext}) => {
  const {tags, tagPostCounts} = pageContext
  return (
    <ArchiveLayout pageTitle="Topics">
      <SEO
        title="Categories | Spark Files"
        description="This is the full category list for all articles on Spark Files."
        keywords={["categories", "topics"]}
      />
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col className="p-0">
            <TitleBar>
              <h1 className="text-center" style={{fontFamily: fonts.mainFont}}>
                Spark Files{" "}
                <span style={{color: colors.meatRare}}>Categories</span>
              </h1>
            </TitleBar>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm="8" className="tags-page pb-5">
            <ListGroup>
              {tags.map(tag => (
                <ListGroupItem
                  key={tag}
		  href={`/category/${slugify(tag)}/`}
                  tag="a"
                  action>
                  {tag} <Badge color="light">{tagPostCounts[tag]}</Badge>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col sm="4">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </ArchiveLayout>
  )
}

export default tagsPage

const TopicsHeading = styled.h1`
  color: #fff;
`
const TitleBar = styled.div`
  background-image: linear-gradient(316deg, ${colors.meatChar} 0%, #131313 84%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  p {
    color: #fff !important;
  }
  a {
    color: #f56545;
    &:hover {
      color: #d25337 !important;
    }
  }
`
